import { createApp } from 'vue';

import '@/assets/styles/themes/valid-theme.css';
import alert from '@/hooks/alert';
import alertAudit from './hooks/alertAudit';
import { getQueryParam } from "@/utils/urlFunctions";
import vm from "@/viewModels/MainViewModel";
import PrimeVue from 'primevue/config';
import App from './App.vue';
import router from './router';

const uf = window.location.href.replace('https://admin-teorico.', "").replace(".superprati.co", "").toUpperCase();
if (uf.length != 2) {
    vm.theme = getQueryParam("theme") ?? (localStorage.getItem("theme") ?? "default-theme");
}
else {
    vm.federativeUnitSelected = uf;
}

const schedulingLocal: string = getQueryParam('schedulingLocal') ?? (localStorage.getItem('EP_SCHEDULING_LOCAL') ?? '1');
if (schedulingLocal) {
    vm.schedulingLocal = schedulingLocal;
}

const app = createApp(App);

app.use(router);
app.use(alert);
app.use(alertAudit);
app.use(PrimeVue, {
    locale: {
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: "Hoje",
        weekHeader:"Sm",
        firstDayOfWeek:	0,
        dateFormat:	"mm/dd/yy",
        weak: "Semana"
    }
});

app.mount('#app');